@import "../../assets/styles/utils";

.root-header{
    &.scroll{
        .header-zhanwei{
            @include res(height, 80px,(sm: 60px));
        }
        .header-container {
          @include res(height, 80px, (sm: 60px));
        }
    }
    &.hide{
        .header-container{
            transform: translateY(-100%);
        }
    }
    .header-zhanwei{
        transition: $anime-duration;
        @include res(height, $header-height-base, $header-height-ratio);
    }
    .header-container{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1010;
        background-color: #fff;
        transition: $anime-duration;
        @include res(height, $header-height-base, $header-height-ratio);
        .header{
            height: 100%;
            background-color: #fff;
            >.container-fluid{
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .hamburger{
                    @include res(display, none, (sm:block));
                }
                .left{
                    height: 100%;
                    display: flex;
                    align-items: center;
                    a{
                        display: block;
                        height: 80%;
                        max-height: 84px;
                        img{
                            display: block;
                            height: 100%;
                            width: auto;
                        }
                    }
                }
                .right{
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    .nav-pc{
                        height: 100%;
                        @include res(display,block,(sm:none));
                        ul{
                            height: 100%;
                            display: flex;
                            align-items: center;
                            li{
                                height: 100%;
                                font-family: 'cnjt';
                                @include res(margin-left,25px, (mmd:13px));
                                @include res(margin-right, 25px, (mmd:13px));
                                >a{
                                    display: flex;
                                    align-items: center;
                                    height: 100%;
                                    color: $color-title;
                                    transition: $anime-duration;
                                    // @include res(margin-left,25px, (mmd:13px));
                                    // @include res(margin-right, 25px, (mmd:13px));
                                    @include res(font-size, 18px, (lg:16px,mmd:14px));
                                    span{
                                        position: relative;
                                        b{
                                            display: block;
                                            width: 0;
                                            height: 2px;
                                            background-color: $color-main;
                                            position: absolute;
                                            bottom: 0;
                                            left: 0;
                                            transition: $anime-duration;
                                        }
                                    }
                                }
                                .subnav{
                                    position: absolute;
                                    left: 0;
                                    right: 0;
                                    top: 100%;
                                    z-index: -1;
                                    background-color: rgba(89,27,24,.9);
                                    color: #bdb29c;
                                    transform: translateY(-100%);
                                    transition: all $anime-duration;
                                    &::before{
                                        content: '';
                                        display: block;
                                        width: 100%;
                                        height: 1px;
                                        background-color: rgba(221,208,181,.5);
                                        position: absolute;
                                        top: 4px;
                                        left: 0;
                                    }
                                    &::after {
                                      content: '';
                                      display: block;
                                      width: 100%;
                                      height: 1px;
                                      background-color: rgba(221, 208, 181, .5);
                                      position: absolute;
                                      bottom: 4px;
                                      left: 0;
                                    }
                                    >.container-fluid{
                                        text-align: right;
                                        a{
                                            display: inline-block;
                                            height: 70px;
                                            line-height: 70px;
                                            transition: $anime-duration;
                                            @include res(margin-left,30px, 15 / 30);
                                            @include res(font-size,16px,(lg:14px));
                                            &:hover{
                                                color: #f5f1e9;
                                            }
                                        }
                                    }
                                }
                                &.active{
                                    >a {
                                      color: $color-main;
                                      span {
                                          b{
                                              width: 100%;
                                          }
                                      }
                                    }
                                }
                                &:hover{
                                    >a{
                                        color: $color-main;
                                    }
                                    .subnav{
                                        transform: translateY(0);
                                    }
                                }
                            }
                        }
                    }
                    .mailbox{
                        height: 100%;
                        @include res(margin-left,25px, (sm:0));
                        a{
                            height: 100%;
                            display: flex;
                            align-items: center;
                            .iconfont{
                                color: $color-main;
                                @include res(font-size,32px, 26 /32);
                            }
                        }
                    }
                }
            }
        }
    }
    .nav-mob {
      position: fixed;
      height: 100vh;
      overflow-x: hidden;
      overflow-y: auto;
      background-color: $color-main;
      width: 100%;
      top: 0;
      left: -100%;
      z-index: 1009;
      box-sizing: border-box;
      font-family: 'cnjt';
      color: #bdb29c;
      transition: $anime-duration;
      @include res(padding-left,40px, 15 / 40);
      @include res(padding-right, 40px, 15 / 40);
      @include res(padding-top, $header-height-base, $header-height-ratio);
      &.active{
        transform: translateX(100%);
      }
      ul{
          li{
              border-bottom: 1px solid #9b7667;
              >a{
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding-left: 10px;
                  padding-right: 10px;
                  font-size: 18px;
                  .iconfont{
                      font-size: 30px;
                      transform: rotate(-90deg);
                      transition: $anime-duration;
                  }
              }
              .subnav{
                  padding-left: 30px;
                  padding-bottom: 20px;
                  display: none;
                  zoom: 1;
                  &::after {
                    content: '';
                    display: block;
                    height: 0;
                    visibility: hidden;
                    clear: both;
                  }
                  a{
                      font-size: 16px;
                      display: block;
                      width: calc(50% - 20px);
                      position: relative;
                      margin-left: 20px;
                      float: left;
                      &::before{
                          content: '';
                          display: block;
                          width: 15px;
                          height: 1px;
                          background-color: $color-line;
                          position: absolute;
                          right: 103%;
                          top: 20px;
                      }
                  }
              }
              &.active{
                  >a{
                      .iconfont{
                          transform: rotate(0deg);
                      }
                  }
              }
          }
      }
    }
}