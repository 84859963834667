@import "./utils";
@import "~jason-css/src/responsive/index";
@import "../fonts/iconfont.css";

// @font-face {
//   font-family: 'cnjt';
//   src: url('../fonts/方正清刻本悦宋简体.eot');
//   src: url('../fonts/方正清刻本悦宋简体.woff') format('woff'),
//     url('../fonts/方正清刻本悦宋简体.ttf') format('truetype'),
//     url('../fonts/方正清刻本悦宋简体.svg') format('svg');
//   font-weight: normal;
//   font-style: normal;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   font-smoothing: antialiased;
// }
// @font-face {
//   font-family: 'cnjt';
//   src: url('../fonts/SourceHanSerifCN-Bold.eot');
//   src: url('../fonts/SourceHanSerifCN-Bold.woff') format('woff'),
//     url('../fonts/SourceHanSerifCN-Bold.ttf') format('truetype'),
//     url('../fonts/SourceHanSerifCN-Bold.svg') format('svg');
//   font-weight: normal;
//   font-style: normal;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   font-smoothing: antialiased;
// }

@font-face {
  font-family: 'cnjt';
  font-display: swap;
  src: url('//at.alicdn.com/t/webfont_cdf9zpfdc3r.eot'); /* IE9*/
  src: url('//at.alicdn.com/t/webfont_cdf9zpfdc3r.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('//at.alicdn.com/t/webfont_cdf9zpfdc3r.woff2') format('woff2'),
  url('//at.alicdn.com/t/webfont_cdf9zpfdc3r.woff') format('woff'), /* chrome、firefox */
  url('//at.alicdn.com/t/webfont_cdf9zpfdc3r.ttf') format('truetype'), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url('//at.alicdn.com/t/webfont_cdf9zpfdc3r.svg#after') format('svg'); /* iOS 4.1- */
}

html{
  overflow-x: hidden;
}
body{
  font-family: 'Times New Roman','微软雅黑';
  overflow-x: hidden;
  line-height: 1.9;
  position: relative;
  color: $color-text;
  background-color: #fff;
  @include res(font-size, $text-size-base, $text-size-ratio);
}

//清除浮动
.clear {
  zoom: 1;

  &::after {
    content: '';
    display: block;
    height: 0;
    visibility: hidden;
    clear: both;
  }
}

//电脑手机适配
.pc-show {
  display: block !important;
}

.pc-show-flex {
  display: flex !important;
}

.mob-show {
  display: none !important;
}

.mob-show-flex {
  display: none !important;
}

//半透明黑色遮罩
.root-mask {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, .6);
  display: none;

  &.mask-top {
    z-index: 10;
  }

  &.mask-bottom {
    z-index: 3;
  }
}

//主体内容部分宽度
.container-fluid {
  box-sizing: content-box;
  width: 100%;
  max-width: 1400px;
  @include res(padding-left, $container-gutter-base, $container-gutter-ratio);
  @include res(padding-right, $container-gutter-base, $container-gutter-ratio);
  &.container-fluid-1200{
    max-width: 1200px;
  }
}

@media screen and (max-width: 1600px) {
  .container-fluid {
    box-sizing: border-box;
    max-width: 1330px;
  }
}
@media screen and (max-width: 1440px) {
  .container-fluid {
    max-width: 1200px;
  }
}

@media screen and (max-width: 768px) {

  .pc-show,
  .pc-show-flex {
    display: none !important;
  }

  .mob-show {
    display: block !important;
  }

  .mob-show-flex {
    display: flex !important;
  }
}

//圆形进度条
@keyframes progress {
  0% {
    stroke-dashoffset: 400%;
  }
  50% {
    stroke-dashoffset: 200%;
  }
  100% {
    stroke-dashoffset: 0%;
  }
}
@keyframes progress1 {
  0% {
    stroke-dashoffset: 400%;
  }
  50% {
    stroke-dashoffset: 200%;
  }
  100% {
    stroke-dashoffset: 0%;
  }
}

@keyframes progress2 {
  0% {
    stroke-dashoffset: 400%;
  }
  50% {
    stroke-dashoffset: 200%;
  }
  100% {
    stroke-dashoffset: 0%;
  }
}

@keyframes rain {
  0% {
    opacity: 0;
    transform: translateY(-20%);
  }
  25% {
    opacity: 0.7;
    transform: translateY(-10%);
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  75% {
    opacity: 0.7;
    transform: translateY(10%);
  }
  100% {
    opacity: 0;
    transform: translateY(20%);
  }
}

//通用列表前横线
.text-has-line{
  p{
    &::before{
      content: '';
      width: 20px;
      height: 1px;
      background-color: $color-main;
      display: inline-block;
      margin-right: 10px;
    }
  }
}
//通用电话样式
.common-tel{
  display: inline-block;
  color: $color-main;
  font-weight: bold;
  background: url(../images/icon_phone.png) no-repeat left center;
  @include res(background-size, 30px auto,(sm: 20px auto));
  @include res(padding-left,40px,30 / 40);
  @include res(font-size, $title-size-sm-base, $title-size-sm-ratio);
}

//通用更多按钮
.common-more{
  box-sizing: border-box;
  display: inline-block;
  border: 2px solid $color-main;
  padding: 2px;
  font-family: 'cnjt';
  text-align: center;
  @include res(width,174px, 150 / 174);
  @include res(height, 58px, 40 / 58);
  @include res(line-height, 50px, 32 / 50);
  span{
    display: inline-block;
    width: 100%;
    height: 100%;
    color: $color-main;
    transition: all $anime-duration;
  }
  &:hover{
    span{
      background-color: $color-main;
      color: $color-main-bg;
    }
  }
}

// 关于我们栏目banner
.about-banner{
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  @include res(height, 570px, 200 / 570);
  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    border: 2px solid $color-line-rgba;
    box-sizing: border-box;
    @include res(width, calc(100% - 80px), (mmd:calc(100% - 40px), md:calc(100% - 20px), xs:calc(100% - 10px)));
    @include res(height, calc(100% - 80px), (mmd:calc(100% - 40px), md:calc(100% - 20px), xs:calc(100% - 10px)));
    @include res(top, 40px, (mmd:20px, md:10px, xs:5px));
    @include res(left, 40px, (mmd:20px, md:10px, xs:5px));
  }
  .location{
    font-size: 14px;
    color: $color-gray;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
    @include res(padding-left, 0, (mmd:20px, md:10px, xs:5px));
    @include res(padding-top, 55px, (mmd:30px, md:15px, xs:0px));
    .iconfont {
      font-size: 26px;
      transform: rotate(-90deg);
      margin-right: -3px;
      @include res(margin-left,5px, 3 / 5);
    }
    a:hover{
      color: #fcf7eb;
    }
  }
}

// 新闻中心栏目banner
.news-banner{
  background-color: $color-main-bg;
  @include res(height, 230px, 180 / 230);
  .container-fluid{
    height: 100%;
    .cont{
      box-sizing: border-box;
      height: 100%;
      background-position: bottom right;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      @include res(background-size, null, (xs:100% auto));
      @include res(padding-top,20px, 10 / 20);
      @include res(padding-bottom, 30px, 15 / 30);
      .location {
        font-size: 14px;
        color: #bdb29c;
        display: flex;
        align-items: center;
        position: relative;
        z-index: 2;
        .iconfont {
          font-size: 26px;
          transform: rotate(-90deg);
          margin-right: -3px;
          @include res(margin-left, 5px, 3 / 5);
        }
        a:hover {
          color: #807867;
        }
      }
      .tabs{
        display: flex;
        font-family: 'cnjt';
        @include res(font-size, $text-size-md-base, $text-size-md-ratio);
        @include res(margin-bottom,0, (mmd:20px,sm:30px,xs:40px));
        a{
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $color-main;
          border-radius: 0;
          background: none;
          border-bottom: solid $color-main;
          border-width: 1px;
          outline: none;
          transition: all .1s;
          @include res(height, 50px, 35 / 50);
          @include res(width, 174px, 110 / 174);
          &:not(:last-child){
            @include res(margin-right, 20px, 10 / 20);
          }
          &:not(.active):hover {
            border-width: 4px;
          }
          &.active{
            background: $color-main;
            color: #f5f1e9;
          }
        }
      }
    }
  }
}

//产品服务banner
.product-banner{
  background-color: $color-main-bg;
  @include res(padding-top, 10px, 0 / 10);
  @include res(padding-bottom, 6px, 30 / 60);
  .location {
    font-size: 14px;
    color: #bdb29c;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;

    .iconfont {
      font-size: 26px;
      transform: rotate(-90deg);
      margin-right: -3px;
      @include res(margin-left, 5px, 3 / 5);
    }

    a:hover {
      color: #807867;
    }
  }
}

//城市运营banner
.csyy-banner{
  background-color: $color-main-bg;
  @include res(padding-top,25px, 10 / 25);
  @include res(padding-bottom, 40px, 20 / 40);
  @include res(margin-bottom, 80px, 30 / 80);
  .location {
    font-size: 14px;
    color: #bdb29c;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
    .iconfont {
      font-size: 26px;
      transform: rotate(-90deg);
      margin-right: -3px;
      @include res(margin-left, 5px, 3 / 5);
    }
    a:hover {
      color: #807867;
    }
  }
  .banner-cont{
    justify-content: space-between;
    @include res(margin-top, 25px, 10 / 25);
    @include res(margin-bottom, 55px, 20 / 55);
    @include res(display,flex,(sm:block));
    @include res(align-items, center, (mmd:flex-start));
    .left{
      flex-shrink: 0;
      @include res(width,61.78%,(mmd:50%,sm:100%));
      img{
        display: block;
        width: 100%;
        height: auto;
      }
    }
    .right {
      flex-shrink: 0;
      @include res(width, 35.71%, (mmd:48%,sm:100%));
      h1{
        color: $color-main;
        font-family: 'cnjt';
        position: relative;
        @include res(margin-top, 0, (sm:10px));
        @include res(text-align, null, (sm:center));
        @include res(font-size, $text-size-md-base, $text-size-md-ratio);
        &::before{
          content: '';
          width: 40px;
          height: 3px;
          background-color: $color-main;
          top: 50%;
          transform: translateY(-50%);
          left: -55px;
          position: absolute;
          @include res(display, block, (sm:none));
        }
      }
      h2{
        font-weight: bold;
        color: $color-main;
        text-transform: uppercase;
        line-height: 0.95;
        @include res(text-align, null, (sm:center));
        @include res(margin-top, 10px, 5 / 10);
        @include res(margin-bottom,25px, 10 / 25);
        @include res(font-size, $title-size-md-base, $title-size-md-ratio);
      }
    }
  }
  .banner-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid $color-line;
    border-bottom: 1px solid $color-line;
    position: relative;
    .icon {
      cursor: pointer;
      border-radius: 50%;
      background-color: $color-main;
      color: #f5f1e9;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      @include res(width, 60px, 40 / 60);
      @include res(height, 60px, 40 / 60);
      .iconfont {
        @include res(font-size, 40px, 28 / 40);
      }
      &:hover{
        .iconfont {
          animation: rain 1s infinite linear;
        }
      }
    }
    a {
      box-sizing: border-box;
      display: flex;
      flex-shrink: 0;
      width: 50%;
      align-items: center;
      @include res(padding-top, 20px, 10 / 20);
      @include res(padding-bottom, 20px, 10 / 20);
      @include res(padding-left, 30px, 10 / 30);
      @include res(padding-right, 30px, 10 / 30);
      &.first {
        border-right: 1px solid $color-line;
        justify-content: flex-start;
        .link-title {
          &::before {
            position: relative;
            left: 0;
            content: '\e811';
            font-family: 'iconfont';
            transition: all $anime-duration;
            @include res(margin-right, 35px, 10 / 35);
          }
        }
        &:hover {
          .link-title {
            &::before {
              left: -10px;
            }
          }
        }
      }
      &.last{
        justify-content: flex-end;
        .link-title {
          justify-content: flex-end;
          text-align: right;
          &::after {
            content: '\e812';
            font-family: 'iconfont';
            position: relative;
            right: 0;
            transition: all $anime-duration;
            @include res(margin-left, 35px, 10 / 35);
          }
        }
        &:hover {
          .link-title {
            &::after {
              right: -10px;
            }
          }
        }
      }
      .link-title {
        display: flex;
        align-items: center;
        color: $color-main-light;
        font-family: 'cnjt';
        @include res(font-size, $text-size-md-base, $text-size-md-ratio);
      }
    }
  }
}

//文化与公益banner
.whygy-banner{
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  box-sizing: border-box;
  @include res(padding-top, 40px, (mmd:20px, md:10px, xs:5px));
  @include res(height,568px,(lg:500px,mmd:460px,sm:400px,xs:300px));
  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    border: 2px solid $color-line-rgba;
    box-sizing: border-box;
    @include res(width, calc(100% - 80px), (mmd:calc(100% - 40px), md:calc(100% - 20px), xs:calc(100% - 10px)));
    @include res(height, calc(100% - 80px), (mmd:calc(100% - 40px), md:calc(100% - 20px), xs:calc(100% - 10px)));
    @include res(top, 40px, (mmd:20px, md:10px, xs:5px));
    @include res(left, 40px, (mmd:20px, md:10px, xs:5px));
  }
  .container-fluid{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    z-index: 2;
    @include res(height, calc(100% - 40px), (mmd:calc(100% - 20px), md:calc(100% - 10px), xs:calc(100% - 5px)));
    .location {
      font-size: 14px;
      color: #f3e3c1;
      display: flex;
      align-items: center;
      @include res(margin-top,10px, 0 / 10);
      .iconfont {
        font-size: 26px;
        transform: rotate(-90deg);
        margin-right: -3px;
        @include res(margin-left, 5px, 3 / 5);
      }
      a:hover {
        color: #f5f1e9;
      }
    }
    .banner-link{
      border-top: 1px solid $color-line-rgba;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include res(padding-top,40px, 15 / 40);
      @include res(padding-bottom, 40px, 15 / 40);
      @include res(position,relative,(sm:static));
      .banner-title{
        position: absolute;
        top: 50%;
        right: 0;
        z-index: 1;
        text-align: center;
        transform: translateY(-50%);
        color: #f5f1e9;
        @include res(width,54%,(mmd:60%,sm:90%));
        @include res(left, 23%, (mmd:20%, sm:5%));
        h1{
          font-family: 'cnjt';
          line-height: 1;
          @include res(margin-bottom,10px, 5 / 10);
          @include res(font-size, $text-size-md-base, $text-size-md-ratio);
        }
        h2{
          font-weight: bold;
          text-transform: uppercase;
          line-height: .9;
          @include res(font-size, $title-size-md-base, $title-size-md-ratio);
        }
      }
      .left-a{
        position: relative;
        z-index: 2;
        color: rgba(245,241,233,.8);
        font-family: 'cnjt';
        @include res(margin-left, 30px, 10 / 30);
        &::before {
          position: relative;
          left: 0;
          content: '\e811';
          font-family: 'iconfont';
          transition: all $anime-duration;
          @include res(margin-right, 35px, 10 / 35);
        }
        &:hover{
          &::before {
            left: -10px;
          }
        }
      }
      .right-a{
        position: relative;
        z-index: 2;
        color: rgba(245, 241, 233, .8);
        font-family: 'cnjt';
        @include res(margin-right,30px, 10 / 30);
        &::after {
          content: '\e812';
          font-family: 'iconfont';
          position: relative;
          right: 0;
          transition: all $anime-duration;
          @include res(margin-left, 35px, 10 / 35);
        }
        &:hover{
          &::after {
            right: -10px;
          }
        }
      }
    }
  }
}
.whygy-title {
  font-family: 'cnjt';
  color: $color-main;
  @include res(text-align, null, (xs:center));
  @include res(margin-bottom, 50px, 10 / 50);
  @include res(font-size, $text-size-md-base, $text-size-md-ratio);

  &::after {
    content: '';
    display: inline-block;
    width: 40px;
    height: 2px;
    background-color: $color-main;
    @include res(margin-left, 15px, 5 / 15);
  }
}