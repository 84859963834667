.page {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 130px;
}

@media (max-width: 1600px) {
  .page {
    margin-top: 46px;
  }
}

@media (max-width: 1359px) {
  .page {
    margin-top: 42px;
  }
}

@media (max-width: 1023px) {
  .page {
    margin-top: 38px;
  }
}

@media (max-width: 991px) {
  .page {
    margin-top: 34px;
  }
}

@media (max-width: 767px) {
  .page {
    margin-top: 30px;
  }
}

@media (max-width: 1600px) {
  .page {
    margin-bottom: 114px;
  }
}

@media (max-width: 1359px) {
  .page {
    margin-bottom: 98px;
  }
}

@media (max-width: 1023px) {
  .page {
    margin-bottom: 82px;
  }
}

@media (max-width: 991px) {
  .page {
    margin-bottom: 66px;
  }
}

@media (max-width: 767px) {
  .page {
    margin-bottom: 50px;
  }
}

.page a {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #5f201c;
  margin: 0 5px;
  font-size: 20px;
  width: 54px;
  height: 54px;
}

@media (max-width: 1600px) {
  .page a {
    font-size: 18px;
  }
}

@media (max-width: 1359px) {
  .page a {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .page a {
    width: 49.2px;
  }
}

@media (max-width: 1359px) {
  .page a {
    width: 44.4px;
  }
}

@media (max-width: 1023px) {
  .page a {
    width: 39.6px;
  }
}

@media (max-width: 991px) {
  .page a {
    width: 34.8px;
  }
}

@media (max-width: 767px) {
  .page a {
    width: 30px;
  }
}

@media (max-width: 1600px) {
  .page a {
    height: 49.2px;
  }
}

@media (max-width: 1359px) {
  .page a {
    height: 44.4px;
  }
}

@media (max-width: 1023px) {
  .page a {
    height: 39.6px;
  }
}

@media (max-width: 991px) {
  .page a {
    height: 34.8px;
  }
}

@media (max-width: 767px) {
  .page a {
    height: 30px;
  }
}

.page a:not(.hover):hover {
  font-weight: bold;
}

.page a.hover {
  background-color: #f5f1e9;
}
