@import "../../assets/styles/utils";

.root-footer{
    background-color: $color-main-light;
    .backTop{
        border-bottom: 1px solid rgba(243, 227, 193, .5);
        margin-bottom: 1px;
        display: flex;
        justify-content: center;
        .backTop-cont{
            text-align: center;
            font-size: 14px;
            color: #ddd0b5;
            cursor: pointer;
            position: relative;
            z-index: 10;
            transition: all .2s;
            top: 0;
            @include res(margin-top, -23px, -17 / -23);
            @include res(margin-bottom,35px,15 / 35);
            .icon{
                background-color: #ddd0b5;
                color: $color-main;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                overflow: hidden;
                transition: all $anime-duration;
                @include res(width,45px, 35 / 45);
                @include res(height, 45px, 35 / 45);
                .iconfont{
                    @include res(font-size,24px, 20 / 24);
                }
            }
            &:hover{
                top: -3px;
               .icon{
                   background-color: #ede3cd;
               } 
            }
        }
    }
    .footer-cont {
        border-top: 1px solid rgba(243, 227, 193, .5);
        border-bottom: 1px solid rgba(243, 227, 193, .5);
        margin-bottom: 1px;
        align-items: center;
        justify-content: center;
        color: #ddd0b5;
        position: relative;
        @include res(display,flex,(sm:block));
        @include res(padding-top,40px, 20 / 40);
        @include res(padding-bottom, 40px, 20 / 40);
        .cont1{
            align-items: center;
            justify-content: flex-start;
            line-height: 1.6;
            left: 0;
            @include res(display, flex, (sm:none));
            @include res(position, absolute, (sm:static));
            .iconfont{
                @include res(margin-right,15px, 10 / 15);
                @include res(font-size,40px, 20/ 40);
            }
            .wx-img{
                position: absolute;
                opacity: 1;
                left: 105%;
                top: -60%;
                opacity: 0;
                transition: $anime-duration;
                img{
                    max-width: 140px;
                    height: auto;
                }
            }
            &:hover{
                .wx-img{
                    opacity: 1;
                }
            }
        }
        .cont2{
            max-width: 435px;
            @include res(width, 33.33%, (sm:80%));
            @include res(margin, null, (sm: 0 auto));
            img{
                display: block;
                width: 100%;
                height: auto;
            }
        }
        .cont3{
            right: 0;
            line-height: 1.2;
            @include res(margin-top, 0, (sm:10px));
            @include res(text-align, right, (sm:center));
            @include res(position, absolute, (sm:static));
            a{
                display: block;
                font-family: 'DIN';
                @include res(font-size,26px, 18 / 26);
            }
        }
    }
    .copyright {
        border-top: 1px solid rgba(243, 227, 193, .5);
        position: relative;
        align-items: center;
        justify-content: center;
        color: #ac7d6d;
        @include res(text-align, null, (sm:center));
        @include res(display, flex, (sm:block));
        @include res(padding-top, 20px, 10 / 20);
        @include res(padding-bottom, 25px, 15 / 25);
        .cont1{
            left: 0;
            @include res(position, absolute, (sm:static));
            span{
                // @include res(margin-right,10px, 0 / 10);
                // @include res(font-size, $text-size-lg-base, $text-size-lg-ratio);
            }
        }
        .cont2{
            a{
                @include res(display,null,(xs:block));
            }
        }
        .cont3 {
          right: 0;
          @include res(position, absolute, (sm:static));
          a{
              margin-left: 15px;
              transition: $anime-duration;
              &:hover{
                  color: #ddd0b5;
              }
              &:first-child{
                  margin-left: 0;
              }
              &:last-child{
                  @include res(display, inline, (sm:none));
              }
          }
        }
    }
}