@import "../../assets/styles/utils";

.common-title{
    text-align: center;
    color: $color-main-light;
    overflow: hidden;
    @include res(margin-top,50px, 20 / 50);
    @include res(margin-bottom, 50px, 20 / 50);
    .cn-title{
        span{
            font-family: 'cnjt';
            position: relative;
            display: inline-block;
            &::before{
                content: '';
                width: 2000px;
                height: 1px;
                border-bottom: 1px dashed $color-line;
                position: absolute;
                top: 50%;
                right: 120%;
            }
            &::after {
                content: '';
                width: 2000px;
                height: 1px;
                border-bottom: 1px dashed $color-line;
                position: absolute;
                top: 50%;
                left: 120%;
            }
        }
    }
    .en-title{
        font-weight: bold;
        text-transform: uppercase;
        @include res(font-size,30px, (lg:26px,mmd:22px,md:20px));
    }
}