@import "../../assets/styles/utils";

.page{
    display: flex;
    align-items: center;
    justify-content: center;
    @include res(margin-top,50px, 30 / 50);
    @include res(margin-bottom, 130px, 50 / 130);
    a{
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        color: $color-main;
        margin: 0 5px;
        @include res(font-size, $text-size-lg-base, $text-size-lg-ratio);
        @include res(width,54px, 30 / 54);
        @include res(height, 54px, 30 / 54);
        &:not(.hover):hover{
            font-weight: bold;
        }
        &.hover{
            background-color: $color-main-bg;
        }
    }
}